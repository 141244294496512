import { Box, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { FormHelperText, FormLabel, Typography, designTokens } from "@platform-ui/design-system";
import { Destination } from "@prequel/react";
import React, { useEffect } from "react"
import { Control, Controller, FieldErrors, FieldValues } from "react-hook-form";

interface BooleanChicletsProps {
    field: any;
    control: Control<FieldValues, any>;
    destination: Destination;
    errors: FieldErrors<FieldValues>
    setDestinationField: (key: keyof Destination, value: string | string[] | boolean | undefined) => void;
    register: (name: keyof Destination, options?: any) => any;
}
function BooleanChiclets({ field, control, destination, errors, setDestinationField, register }: BooleanChicletsProps) {

    useEffect(() => {
        if (!destination[field.name] && field.required) {
            destination[field.name] = field.enum[0];
        }
    })
    return (
        <Controller
            name={field.name}
            control={control}
            render={({ field: { onChange, value } }) => (
                <>
                    <FormLabel required={field.required}>{field.label}</FormLabel>
                    <List
                        {...register(field.name, {
                            validate: (value, formValues) => {
                                if (field.enum && field.enum.length > 0 && field.enum.find((item) => item.key === destination[field.name]) === undefined) {
                                    return `${field.label || 'This'} is a required field`;
                                }
                                return true;
                            }
                        })}
                        sx={{ display: 'flex' }}>
                        {field.enum.map((item) => (
                            <ListItemButton
                                key={item.key as string}
                                selected={destination[field.name] === item.key}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setDestinationField(field.name, item.key as string)
                                }}
                                alignItems="center"
                                sx={{
                                    border: '1px solid', borderColor: destination[field.name] === item.key ? designTokens.colors.blue300 : designTokens.colors.coolGray100,
                                    marginRight: '1rem',
                                    maxWidth: '40rem',
                                    maxHeight: '10rem',
                                    // width: '40rem',
                                    // height: '10rem',
                                }}
                            >
                                {item.icon_url && <ListItemIcon sx={{ marginRight: '1%' }}>
                                    <Box sx={{
                                        backgroundColor: destination[field.name] === item.key ? '#d4e6fe' : '#f5f6f7',
                                        borderRadius: '50%',
                                        textAlign: 'center',
                                        verticalAlign: 'middle',
                                        height: '7rem',
                                        width: '7rem',
                                        maxHeight: '7rem',
                                        maxWidth: '7rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        {item.icon_url && <img src={item.icon_url} alt={item.key as string} />}
                                    </Box>
                                </ListItemIcon>}
                                <ListItemText primary={
                                    <Typography truncated noWrap variant="title">{item.display}</Typography>
                                } />
                            </ListItemButton>
                        ))}
                    </List>
                    {field.enum && field.enum.length > 0 && field.enum.find((item) => item.key === destination[field.name]) === undefined && <Typography variant="caption" color={designTokens.colors.red500} body={errors && errors[field.name] && errors[field.name].message ? errors[field.name].message as string : ''} />}
                    <FormHelperText label={field.description}></FormHelperText>
                </>
            )}
        />
    )
}

export default BooleanChiclets