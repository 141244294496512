import Connect from "../../../../Connect/Connect";
const fetchAuthToken: (url: string) => Promise<string> = async (url) => {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute("content")
      },
      body: JSON.stringify({}),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch token: ${response.statusText}`);
    }

    const body = await response.json();
    const token = body.scoped_token;
    return token;
  } catch (error) {
    Connect.log(error);
  }
}


export default fetchAuthToken;