import { Box, FormHelperText, IconButton } from "@mui/material";
import { Card, Grid, Icon, Typography } from "@platform-ui/design-system";
import React, { useState } from "react"

function ConstField({ field, showDescription }) {

    const [copied, setCopied] = useState(false);
    return (
        <>
            <Typography variant="subtitle1">{field.label}</Typography>
            <Box sx={{ position: "relative", border: '1px solid lightgray', borderRadius: '5px' }}>
                <IconButton
                    style={{ position: "absolute", top: -5, right: -5 }}
                    onClick={() => {
                        navigator.clipboard.writeText(JSON.stringify(field.const, null, 2))
                            .then(() => {
                                setCopied(true)
                                setTimeout(
                                    function () {
                                        setCopied(false)
                                    }
                                        .bind(this),
                                    3000
                                );
                            })
                            .catch((e) => { console.log(e) });
                    }}
                >
                    <Icon body={copied ? "done" : "content_copy"} tooltip={<Typography variant='body1' body={copied ? 'copied' : 'copy to clipboard'} />} />
                </IconButton>
                <pre style={{
                    textAlign: "left",
                    border: 0,
                    margin: 0,
                    maxHeight: "100px", // Adjust height as needed
                    // maxWidth: "400px", // Adjust width as needed
                    overflow: "scroll",
                    paddingRight: '32px',
                    borderRadius: '5px'
                }}>
                    <code><Typography variant="code">{JSON.stringify(field.const, null, 2).replace(/[\/\(\)\"]/g, '')}</Typography></code>
                </pre>
            </Box>
            {showDescription && <FormHelperText>{field.description}</FormHelperText>}
        </>
    )
}

export default ConstField