import { Box, FormGroup } from "@mui/material";
import { Button, Card, Checkbox, FormLabel, Grid, Modal, ModalTitle, Typography, designTokens } from "@platform-ui/design-system";
import React, { useState } from "react"
import { DataGrid, GridRowSelectionModel, GridToolbar } from '@mui/x-data-grid';

function DisplayModels({ rows, futureObjectsSyncOn }) {
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>(Array.from({ length: rows?.length }, (_, index) => index + 1));
    const [modalOpen, setModalOpen] = useState(false);
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 10,
        page: 0,
    });

    return (
        <Box sx={{minHeight: '15%'}}>
            <Typography variant="title">Selected Zuora Objects to receive Data</Typography>
            <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                <Typography variant="body2" body='Billing Standard Objects' />
                <Button sx={{ marginBottom: '1%' }}
                    body='View Details'
                    dsOnClick={(e) => {
                        setModalOpen(true);
                    }}
                    variant="text" />
            </Box>
            <Modal
                maxWidth="sm"
                fullWidth
                open={modalOpen}
                id="connector-select-models"
                dsOnClose={() => setModalOpen(false)}
                header={<ModalTitle dsOnClose={() => setModalOpen(false)}>Select Billing Standard Objects</ModalTitle>}
                body={
                    <DataGrid
                        autoHeight
                        disableRowSelectionOnClick
                        paginationModel={paginationModel}
                        isRowSelectable={() => false}
                        pageSizeOptions={[5, 10, 20]}
                        onPaginationModelChange={setPaginationModel}
                        sx={{
                            '.MuiDataGrid-toolbarContainer': {
                                display: 'block',
                            },
                            '.MuiTablePagination-selectLabel': {
                                margin: '0px'
                            },
                            '.MuiTablePagination-displayedRows': {
                                margin: '0px'
                            },
                            minHeight: ''
                        }}
                        rows={rows}
                        disableColumnFilter
                        checkboxSelection
                        disableColumnSelector
                        disableDensitySelector
                        disableColumnMenu
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                csvOptions: { disableToolbarButton: true },
                                printOptions: { disableToolbarButton: true }
                            },
                            filterPanel: {
                                disableAddFilterButton: true
                            }
                        }}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setRowSelectionModel(newRowSelectionModel);
                        }}
                        rowSelectionModel={rowSelectionModel}
                        columns={[
                            {
                                field: 'id',
                                headerName: 'Number',
                            },
                            {
                                field: 'objectName',
                                headerName: 'Object Name',
                                sortable: true,
                                width: 200,
                            }
                        ]}
                    />
                }
                footer={
                    <Card id='select-future-objects' body={
                        <FormGroup>
                            <Checkbox
                                disabled
                                label={
                                    <Typography variant="title">All current and future objects will be synced</Typography>
                                }
                                dsOnChange={(e) => { }}
                                checked={futureObjectsSyncOn}
                            />
                            <Typography variant='caption' color={designTokens.colors.coolGray300}>Objects added in the future will be synced automatically</Typography>
                            <Grid container direction="row" justify="flex-end">
                                <Grid item>
                                    <Button
                                        dsOnClick={(e) => {
                                            setModalOpen(false);
                                        }}
                                        body='Close'
                                        size="medium"
                                    />
                                </Grid>
                            </Grid>
                        </FormGroup>
                    }>
                    </Card>
                }
            />
        </ Box >
    );
}

export default DisplayModels