import React from "react"
import { Card, Chip, Grid, Typography, designTokens } from "@platform-ui/design-system";
import { ExistingDestination, Form, TransferStatus } from "@prequel/react";
import { Box } from "@mui/material";
import { BillingModelMetaData, VENDOR_TO_CONNECTOR_TITLE_MAPPING } from "../Constants";
import DisplayModels from "./DisplayModels";

function findNested(obj, key) {
    // Base case: if obj is not an object or is null, return undefined
    if (typeof obj !== 'object' || obj === null) {
        return undefined;
    }
    // If the key is found at the current level, return its value
    if (obj.hasOwnProperty(key)) {
        return obj[key];
    }
    for (let prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            const result = findNested(obj[prop], key);
            if (result !== undefined) {
                return result;
            }
        }
    }
    return undefined;
}

interface DestinationDetailsProps {
    vendor: string;
    destinationData: ExistingDestination;
    destinationForm: Form;
    models: BillingModelMetaData[];
}

const staticDetailItems = [{ key: 'id', label: 'ID' }, { key: 'is_enabled', label: 'Is Enabled' }, { key: 'vendor', label: 'Vendor' }]
const lightGrayBorder = '1px solid lightgray';

function DestinationDetails({ vendor, destinationData, destinationForm, models }: DestinationDetailsProps) {
    const getModelsList = () => {
        if (destinationData?.enabled_models?.length === 1 && destinationData?.enabled_models?.toString() === ["*"].toString()) {
            return models
        }
        return destinationData?.enabled_models?.map((modelName, index) => {
            return {
                objectName: modelName,
                id: index + 1
            } as BillingModelMetaData
        });
    }

    return <>
        <Card autoDistance
            space='large' id='connector-details'
            header={
                <Typography variant='h5'>
                    {`${VENDOR_TO_CONNECTOR_TITLE_MAPPING[vendor]} Connection `} {destinationData && <Chip label={destinationData.last_completed_transfer ? 'Active' : 'In Progress'} state={destinationData.last_completed_transfer ? 'success' : 'primary'} />}
                </Typography>
                // <>
                //     <Grid container>
                //         <Grid item><ConnectorMetaInfo metaDataTitle='Last Updated By' metaData='zuora@zuora.com' showBarAtEnd={true}></ConnectorMetaInfo></Grid>
                //         <Grid item><Box sx={{ display: 'flex' }}><Typography variant='label' color='gray' body='|' /></Box></Grid>
                //         <Grid item><ConnectorMetaInfo metaDataTitle='Last Updated Date' metaData={destinationData?.created_at} showBarAtEnd={false}></ConnectorMetaInfo></Grid>
                //     </Grid>
                // </>
            }
            titleBar
            body={
                <Box sx={{ width: '70%' }}>
                    <Typography variant='title' body='Details' sx={{ paddingBottom: '2%', borderBottom: `${lightGrayBorder}` }}></Typography>
                    {staticDetailItems.map((item) => {
                        return <Grid key={`destination-details-static-item-${item.key}`} container spacing={0} sx={{ paddingTop: '2%', paddingBottom: '2%', borderBottom: `${lightGrayBorder}` }}>
                            <Grid item xs={5} justify="flex-start">
                                <Typography color={designTokens.colors.coolGray500} variant='body1' body={item.label} />
                            </Grid>
                            <Grid item xs={7} justify="flex-start">
                                <Typography variant="body1" body={item.key === 'is_enabled' ? destinationData[item.key] ? 'Yes' : 'No' : destinationData[item.key]} noWrap tooltip={destinationData[item.key]?.toString()} tooltipOnlyWhenTruncated />
                            </Grid>
                        </Grid>
                    })}

                    {destinationForm?.map((section) => (
                        <Box key={`${section.id}`}>
                            {
                                section.id > 2 && <>
                                    {section?.fields?.map((field) => {
                                        return <Grid key={`destination-details-static-item-${field.name}`}
                                            container spacing={0}
                                            sx={{ paddingTop: '2%', paddingBottom: '2%', borderBottom: `${lightGrayBorder}` }}>
                                            <Grid item xs={5} justify="flex-start">
                                                <Typography color={designTokens.colors.coolGray500} variant="body1" body={field?.label || field?.name} />
                                            </Grid>
                                            <Grid item xs={7} justify='flex-start'>
                                                <Typography variant='body1' body={findNested(destinationData, field.name)?.toString()} />
                                            </Grid>
                                        </Grid>
                                    })}
                                </>
                            }
                        </Box>
                    ))}

                    <Box sx={{ marginTop: '2%' }}>
                        <DisplayModels
                            futureObjectsSyncOn={destinationData?.enabled_models?.length === 1 && destinationData?.enabled_models?.toString() === ['*'].toString()}
                            rows={getModelsList()} />
                    </Box>
                </Box>
            }>
        </Card >
    </>
}

export default DestinationDetails 