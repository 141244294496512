import { PreparedDestination } from "@prequel/react";
import Connect from "../../../../Connect/Connect";

const fetchAuthTokenWithDestination: (url: string, d?: PreparedDestination) => Promise<string> = async (url, destination) => {
  return fetch(url, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute("content")
    },
    body: JSON.stringify(destination),
  })
    .then((response: Response) => {
      return response.json()
    })
    .then((body) => {
      return body.scoped_token
    })
    .catch((e) => {
      Connect.log(e);
      console.error(e);
    });
}

export default fetchAuthTokenWithDestination;